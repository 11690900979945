var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', [_c('b-col', {
    attrs: {
      "md": "8",
      "sm": "8"
    }
  }, [_c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-body', [_c('b-card-text', {
    staticClass: "blog-content-truncate"
  }, [_vm.form ? _c('section', [_c('b-tabs', {
    attrs: {
      "fill": ""
    }
  }, _vm._l(_vm.form, function (data, index) {
    return _c('div', {
      key: index
    }, [_c('b-tab', {
      scopedSlots: _vm._u([{
        key: "title",
        fn: function fn() {
          return [data.aspect == 'filter_by_provinsi' ? _c('label', {
            staticClass: "font-weight-bold"
          }, [_vm._v("Ranking Provinsi")]) : _vm._e(), data.aspect == 'filter_by_sekolah' ? _c('label', {
            staticClass: "font-weight-bold"
          }, [_vm._v("Ranking Sekolah")]) : _vm._e(), data.aspect == 'kelulusan' ? _c('label', {
            staticClass: "font-weight-bold"
          }, [_vm._v("Kelulusan Siswa")]) : _vm._e(), data.aspect == 'kecocokan' ? _c('label', {
            staticClass: "font-weight-bold"
          }, [_vm._v("Kecocokan Jurusan")]) : _vm._e()];
        },
        proxy: true
      }], null, true)
    }, [_c('b-form', [_c('b-row', [_c('b-col', [_c('label', {
      staticClass: "font-weight-bold"
    }, [_c('h5', {
      staticClass: "text-success"
    }, [_vm._v(" Deskripsi untuk siswa yang memenuhi kriteria ")])]), _c('quill-editor', {
      attrs: {
        "id": "Konten",
        "options": _vm.editor_options
      },
      model: {
        value: data.description_in_condition,
        callback: function callback($$v) {
          _vm.$set(data, "description_in_condition", $$v);
        },
        expression: "data.description_in_condition"
      }
    })], 1)], 1), _c('b-row', [_c('b-col', [_c('label', {
      staticClass: "font-weight-bold mt-2"
    }, [_c('h5', {
      staticClass: "text-danger"
    }, [_vm._v(" Deskripsi untuk siswa yang tidak memenuhi kriteria ")])]), _c('quill-editor', {
      attrs: {
        "id": "Konten",
        "options": _vm.editor_options
      },
      model: {
        value: data.description_out_condition,
        callback: function callback($$v) {
          _vm.$set(data, "description_out_condition", $$v);
        },
        expression: "data.description_out_condition"
      }
    })], 1)], 1), _c('b-row', [_c('b-col', [_c('b-button', {
      staticClass: "mt-2 mr-1 btn-icon",
      attrs: {
        "type": "submit",
        "variant": "outline-success"
      },
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return _vm.Simpan(data);
        }
      }
    }, [_c('feather-icon', {
      staticClass: "mr-25",
      attrs: {
        "icon": "SaveIcon"
      }
    }), _vm._v("Simpan ")], 1)], 1)], 1)], 1)], 1)], 1);
  }), 0)], 1) : _vm._e()])], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "md": "4",
      "sm": "4"
    }
  }, [_c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-body', [_c('b-card-text', {
    staticClass: "blog-content-truncate"
  }, [_c('b-alert', {
    attrs: {
      "variant": "warning",
      "show": ""
    }
  }, [_c('h4', {
    staticClass: "alert-heading"
  }, [_vm._v("Informasi")]), _c('div', {
    staticClass: "alert-body"
  }, [_vm._v(" Pilih komponen dibawah untuk otomatis memunculkan data yang akan digunakan di deskripsi SNBP, dengan cara copy lalu paste salah satu di kolom komponen (simbol dollar '$' harus terbawa) ")])]), _c('b-table-simple', {
    attrs: {
      "hover": "",
      "small": "",
      "caption-top": "",
      "responsive": ""
    }
  }, [_c('b-thead', {
    attrs: {
      "head-variant": "light"
    }
  }, [_c('b-tr', [_c('b-th', [_vm._v("Komponen")]), _c('b-th', [_vm._v("Keterangan")])], 1)], 1), _c('b-tbody', _vm._l(_vm.komponen, function (k, index) {
    return _c('b-tr', {
      key: index
    }, [_c('b-td', [_vm._v(_vm._s(k.kom))]), _c('b-td', [_vm._v(_vm._s(k.des))])], 1);
  }), 1)], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }