``
<template>
  <b-row>
    <b-col md="8" sm="8">
      <b-card no-body>
        <b-card-body>
          <!-- <b-card-title>
            <b-row>
              <b-col><div>Setting Deskripsi</div></b-col>
              <b-col>
                <div align="right">
                </div>
              </b-col></b-row
            >
          </b-card-title> -->
          <b-card-text class="blog-content-truncate">
            <section v-if="form">
              <b-tabs class="" fill>
                <div v-for="(data, index) in form" :key="index">
                  <b-tab>
                    <template #title>
                      <label
                        v-if="data.aspect == 'filter_by_provinsi'"
                        class="font-weight-bold"
                        >Ranking Provinsi</label
                      >
                      <label
                        v-if="data.aspect == 'filter_by_sekolah'"
                        class="font-weight-bold"
                        >Ranking Sekolah</label
                      >
                      <label
                        v-if="data.aspect == 'kelulusan'"
                        class="font-weight-bold"
                        >Kelulusan Siswa</label
                      >
                      <label
                        v-if="data.aspect == 'kecocokan'"
                        class="font-weight-bold"
                        >Kecocokan Jurusan</label
                      >
                    </template>

                    <b-form>
                      <b-row>
                        <b-col>
                          <label class="font-weight-bold"
                            ><h5 class="text-success">
                              Deskripsi untuk siswa yang memenuhi kriteria
                            </h5></label
                          >
                          <quill-editor
                            id="Konten"
                            v-model="data.description_in_condition"
                            :options="editor_options"
                          />
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col>
                          <label class="font-weight-bold mt-2"
                            ><h5 class="text-danger">
                              Deskripsi untuk siswa yang tidak memenuhi kriteria
                            </h5></label
                          >
                          <quill-editor
                            id="Konten"
                            v-model="data.description_out_condition"
                            :options="editor_options"
                          />
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col>
                          <b-button
                            type="submit"
                            variant="outline-success"
                            class="mt-2 mr-1 btn-icon"
                            @click.prevent="Simpan(data)"
                          >
                            <feather-icon icon="SaveIcon" class="mr-25" />Simpan
                          </b-button>
                        </b-col>
                      </b-row>
                    </b-form>
                  </b-tab>
                </div>
              </b-tabs>
            </section>
          </b-card-text>
        </b-card-body>
      </b-card>
    </b-col>
    <b-col md="4" sm="4">
      <b-card no-body>
        <b-card-body>
          <b-card-text class="blog-content-truncate">
            <b-alert variant="warning" show>
              <h4 class="alert-heading">Informasi</h4>
              <div class="alert-body">
                Pilih komponen dibawah untuk otomatis memunculkan data yang akan
                digunakan di deskripsi SNBP, dengan cara copy lalu paste salah
                satu di kolom komponen (simbol dollar '$' harus terbawa)
              </div>
            </b-alert>
            <b-table-simple hover small caption-top responsive>
              <b-thead head-variant="light">
                <b-tr>
                  <b-th>Komponen</b-th>
                  <b-th>Keterangan</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr v-for="(k, index) in komponen" :key="index">
                  <b-td>{{ k.kom }}</b-td>
                  <b-td>{{ k.des }}</b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-card-text>
        </b-card-body>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import {
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTd,
  BTbody,
  BTfoot,
  BAlert,
  BTabs,
  BTab,
  BImg,
  BRow,
  BCol,
  BTable,
  BCard,
  BCardText,
  BCardTitle,
  BCardBody,
  BPagination,
  BProgress,
  BBadge,
  BButton,
  BModal,
  VBModal,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormFile,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BTfoot,
    BAlert,
    BTabs,
    BTab,
    AppCollapse,
    AppCollapseItem,
    BFormFile,
    BImg,
    quillEditor,
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardTitle,
    BCardBody,
    BTable,
    BPagination,
    BProgress,
    BBadge,
    BButton,
    BModal,
    VBModal,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    ToastificationContent,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      id: null,
      activeAction: null,
      label: "Simpan",
      editor_options: {
        modules: {
          toolbar: {
            container: [
              ["bold", "italic", "underline", "strike"],
              ["blockquote", "code-block"],
              [{ header: 1 }, { header: 2 }],
              [{ list: "ordered" }, { list: "bullet" }],
              [{ script: "sub" }, { script: "super" }],
              [{ indent: "-1" }, { indent: "+1" }],
              [{ direction: "rtl" }],
              [{ size: ["small", false, "large", "huge"] }],
              [{ header: [1, 2, 3, 4, 5, 6, false] }],
              [{ color: [] }, { background: [] }],
              [{ font: [] }],
              [{ align: [] }],
              ["clean"],
            ],
          },
        },
      },
      komponen: [
        { kom: "$user_name", des: "Nama siswa" },
        { kom: "$nilai_kecocokan", des: "Nilai Awal Siswa (Nilai Kecocokan)" },
        { kom: "$nilai_kelulusan", des: "Nilai Akhir Siswa (Nilai Kelulusan)" },
        { kom: "$school_name", des: "Nama Sekolah Siswa" },
        { kom: "$provinsi_name", des: "Provinsi Siswa" },
        { kom: "$major_name", des: "Nama Jurusan Pilihan Siswa" },
        { kom: "$ranking_ke", des: "Urutan Ranking Siswa" },
        { kom: "$ranking_dari", des: "Jumlah Ranking" },
      ],

      ModalUploadThumbnail: false,
      //Data
      data: [],
      form: {},
      foto: null,
      //Modal
      titleModal: null,
      Modal: false,
      ModalEdit: false,
      //Table
      fields: [
        { key: "index", label: "No" },
        { key: "name", label: "Nama", sortable: true },
        { key: "school_origin", label: "Asal Sekolah ", sortable: true },
        {
          key: "school_accreditation",
          label: "Akreditasi Sekolah",
          sortable: true,
        },
        { key: "created_at", label: "Tanggal Daftar", sortable: true },
        { key: "detail", label: "" },
      ],
      selected: [],
      total: [],
      record: [],
      optFilter: [
        { id: "name", value: "Nama" },
        // { id: "nip", value: "NIP" },
      ],
      perPage: 10,
      pageOptions: [10, 30, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      // filter: "Kategori",
      search: null,
      idd: null,
    };
  },
  watch: {},
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  methods: {
    // ModalUpload() {
    //   this.id = form.id;
    //   this.form = form;
    //   this.activeAction = "upload";
    //   this.titleModal = "Upload Thumbnail";
    //   this.ModalUploadThumbnail = true;
    // },
    tutupModal() {
      this.ModalModalUploadThumbnail = false;
      this.getData();
    },
    doFormatRupiah() {
      this.form.price = this.formatRupiah(this.form.price);
    },
    pesanGagal() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notifikasi",
            icon: "CheckIcon",
            text: "Gagal menyimpan data, harap cek kembali data yang diisi",
            variant: "warning",
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
    Simpan(data) {
      if (
        data.description_in_condition == null ||
        data.description_in_condition == ""
      ) {
        this.pesanGagal();
        return false;
      }
      this.$swal({
        title: "Anda Yakin?",
        text: "Data akan diperbarui",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Batal",
        confirmButtonText: "Ya, Simpan!",
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          const fd = new FormData();
          fd.append("id", data.id);
          fd.append("aspect", data.aspect);
          let descriptionIn = data.description_in_condition || "";
          descriptionIn = descriptionIn.replaceAll("style=", "style2=");
          fd.append("description_in_condition", descriptionIn);

          let descriptionOut = data.description_out_condition || "";
          descriptionOut = descriptionOut.replaceAll("style=", "style2=");
          fd.append("description_out_condition", descriptionOut);
          // if (this.id) {
          //   fd.append("id", this.form.id);
          // }

          this.$store
            .dispatch("snbp/createUpdateDeletedescription", fd)
            .then(() => {
              this.getData();
              this.displaySuccess({
                text: "Setting Deskripsi berhasil disimpan",
              });
              this.$swal({
                icon: "success",
                title: "Setting Deskripsi",
                text: "Data berhasil disimpan.",
                timer: 3000,
                allowOutsideClick: true,
                customClass: {
                  confirmButton: "btn btn-success",
                },
                buttonsStyling: false,
              });
            })
            .catch((e) => {
              this.displayError(e);
              return false;
            });
        } else if (result.dismiss === "cancel") {
          this.$swal({
            title: "Batal",
            text: "Data tidak jadi disimpan",
            icon: "error",
            // timer: 1000,
            allowOutsideClick: true,
            customClass: {
              confirmButton: "btn btn-success",
            },
            buttonsStyling: false,
          });
        }
      });
    },
    Tambah() {
      if (
        this.form.description_in_condition == null ||
        this.form.description_in_condition == ""
      ) {
        this.pesanGagal();
        return false;
      }
      const fd = new FormData();
      fd.append("id", this.form.id);
      let descriptionIn = this.form.description_in_condition || "";
      descriptionIn = descriptionIn.replaceAll("style=", "style2=");
      fd.append("description_in_condition", descriptionIn);

      let descriptionOut = this.form.description_out_condition || "";
      descriptionOut = descriptionOut.replaceAll("style=", "style2=");
      fd.append("description_out_condition", descriptionOut);
      if (this.id) {
        fd.append("id", this.form.id);
      }

      this.$store
        .dispatch("snbp/createUpdateDeletedescription", fd)
        .then(() => {
          this.getData();
          this.displaySuccess({
            text: "Setting Deskripsi berhasil disimpan",
          });
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },
    Upload() {
      if (this.form.thumbnail == null || this.form.thumbnail == "") {
        this.pesanGagal();
        return false;
      }
      const fd = new FormData();
      fd.append(
        "thumbnail",
        this.form.thumbnail == null ? 0 : this.form.thumbnail
      );
      fd.append("name", this.form.name == null ? 0 : this.form.name);
      fd.append(
        "price",
        this.unFormatRupiah(this.form.price) == null
          ? 0
          : this.unFormatRupiah(this.form.price)
      );
      let description = this.form.description || "";
      description = description.replaceAll("style=", "style2=");
      fd.append("description", description);
      if (this.id) {
        fd.append("id", this.form.id);
      }

      this.$store
        .dispatch("snbp/createUpdateDeleteSetting", fd)
        .then(() => {
          this.getData();
          this.displaySuccess({
            text: "Upload thumbnail SNBP berhasil disimpan",
          });
          this.form.thumbnail = null;
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },
    getData() {
      const params = {};
      this.$store
        .dispatch("snbp/indexdescription", params)
        .then((response) => {
          let data = response.data.data;
          this.form = data;
          // this.totalRows = this.form.length;
          // this.foto = this.form.thumbnail;
          this.doFormatRupiah();
        })
        .catch((error) => {
          this.$root.$emit("errorData", error);
        })
        .finally(() => {
          this.selected = [];
        });
    },
    // detail(data) {
    //   this.$store.commit("snbp/SET_DETAIL", data.item);
    //   this.$router.push({ name: "admin-snbp-detail" });
    // },
  },
  created() {},
  async mounted() {
    this.getData();
    this.doFormatRupiah();
  },
};
</script>

<style></style>
